/**
 * @author  XuHongli
 * @date  2022/2/18 17:32
 * @version 1.0
 * @description
 */
import request from '@/utils/request'

/**
 * 获取可用语言列表
 * @returns {*}
 */
export function getLanguageList() {
  return request({
    url: '/shoplanguage/list',
    method: 'get'
  })
}

/**
 * 设置语言
 * @param id
 * @returns {*}
 */
export function setLanguage(id) {
  return request({
    url: '/shoplanguage/updateShopLanguage',
    method: 'post',
    data: { id }
  })
}
