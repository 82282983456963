/**
* @author  XuHongli
* @date  2022/2/17 15:39
* @version 1.0
* @description Shop 账号信息
*/
<template>
  <div class="accountInfo">
    <page-layout title="基础信息">
      <div class="box">
        <h3>账号信息</h3>
        <p style="margin: 10px 0">
          账户语言是你进入店铺后台后看到的语言，它不会影响你线上商店客户的浏览语言
        </p>
        <a-select v-model="languageId" placeholder="请选择" style="width: 200px" @change="changeLanguage">
          <a-select-option v-for="(item,index) in langList" :key="index" :value="item.id">
            {{ item.name }}
          </a-select-option>
        </a-select>
      </div>
    </page-layout>
    <page-layout v-if="roleCode === 'MASTER_ACCOUNT'" title="安全信息">
      <div class="box">
        <h3>绑定账号</h3>
        <p style="margin: 20px 0">
          {{ userInfo.email }}
        </p>
      </div>
    </page-layout>
    <page-layout v-if="roleCode === 'MASTER_ACCOUNT'" title="支付方式">
      <div class="box">
        <span style="font-size: 16px;font-weight: bolder">我的支付方式</span>
        <router-link style="color: #0364FF;margin-left: 32px;cursor: pointer" to="/shop/credit">
          管理支付方式
        </router-link>
        <a-row v-for="(item,index) in cardList" :key="index" :gutter="[24,24]" style="margin-top: 10px">
          <a-col :md="8" :span="24">
            <CardCredit :item="item" />
          </a-col>
        </a-row>
      </div>
    </page-layout>
  </div>
</template>

<script>
import PageLayout from 'views/shop/components/pageLayout'
import CardCredit from 'views/shop/components/CardCredit/CardCredit'
import { getLanguageList, setLanguage } from '@/api/lang'
import { getUserInfo } from '@/api/register'
import { getBankCardList } from '@/api/masterCard'
import { mapGetters } from 'vuex'

export default {
  name: 'AccountInfo',
  components: { CardCredit, PageLayout },
  data() {
    return {
      languageId: '',
      email: 'wkom@qq.com',
      userInfo: {},
      langList: [],
      cardList: [] // 银行卡列表
    }
  },
  computed: {
    ...mapGetters([
      'roleCode'
    ])
  },
  created() {
    getLanguageList().then((res) => {
      this.langList = res.data
    })
    getUserInfo().then((res) => {
      this.languageId = res.data.languageId
      this.userInfo = res.data
    })

    /** 获取银行卡 */
    getBankCardList().then(res => {
      if (res.code === 0) {
        if (res.data.records && res.data.records.length > 0) {
          this.cardList = [res.data.records[0]]
        }
      }
    })
  },
  methods: {
    changeLanguage(value) {
      console.log(`selected ${value}`)
      this.languageId = value
      setLanguage(this.languageId).then((res) => {
        console.log(res)
        if (res.code === 0) {
          this.$notification.success({
            title: res.msg
          })
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.accountInfo {
  min-height: 100%;
  background-color: #ececec;
}
h3{
  font-size: 16px;
}
.box{
  text-align: left;
}
</style>
